import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import {
  Button,
  MainHeading,
  MuiTextfield,
  PublicPrivateSwitch,
} from "../../components/Profile";
import appImages from "../../constants/AppImage";
import { Link, useParams } from "react-router-dom";
import utils, { verifyEnvironment } from "../../utils/commonUtils";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Typography } from "../../components";
import ReCAPTCHA from "react-google-recaptcha";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { signup } from "../../redux/actions/profileActions";
import { GoogleIcon, FacebookIcon } from "../../constants/svgIcons";
import app from "../../configs/firebaseConfig";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
facebookProvider.setCustomParameters({
  prompt: "select_account",
});
facebookProvider.addScope("email");
googleProvider.addScope("email");
facebookProvider.addScope("user_friends");

const {
  profileImages: { createProfileImage },
} = appImages;

let signupFlag = false;

const useStyle = makeStyles((theme) => ({
  hLoginFormOR: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
    width: "100% !important",
    "&>p": {
      textAlign: "center",
      position: "relative",
      "&:before": {
        top: "50%",
        width: "80%",
        height: "1px",
        content: "''",
        position: "absolute",
        transform: "translate(-50%,-50%)",
        left: "50%",
        backgroundColor: "#28a745",
      },
      "&>span": {
        padding: "0 5px",
        position: "relative",
        backgroundColor: "#fff",
      },
    },
  },
}));

const auth = getAuth();
export function CreateProfile({
  match,
  history,
  signupData,
  handleSignupDataChange,
  verifySignupForm,
  profile_picture,
  setProfile_picture,
  handleMasterToggle,
  signup,
  profileSignup,
  handleClose,
}) {
  const classes = useStyle();
  const captchaRef = useRef(null);
  const { token } = useParams();
  const handleSubmit = () => {
    if (verifySignupForm("create-profile")) {
      const captchaToken = captchaRef.current.getValue();
      if (!captchaToken) {
        NotificationManager.error(
          "Please check the checkbox i'm not a robot.",
          "",
          5000
        );
        return;
      }
      signupFlag = true;
      let data_visibility = {};
      let formData = new FormData();
      for (let item of Object.entries(signupData)) {
        const [key, value] = item;
        formData.append(key, value?.value);
        data_visibility = {
          ...data_visibility,
          [key]: value?.isPublic,
        };
      }
      formData.append("user_type", "normal");
      formData.append("data_visibility", JSON.stringify(data_visibility));
      formData.append("recaptcha_token", captchaToken);
      const { latitude, longitude } = utils.getLocalStorageItems();
      if (latitude && longitude) {
        formData.append("latitude", latitude);
        formData.append("longitude", longitude);
      }
      if (profile_picture) {
        formData.append("profile_picture", profile_picture);
      }
      if (token) {
        formData.append("referralToken", token);
      }
      signup(formData);
    }
  };
  const getGeolocationOfUser = () => {
    navigator.geolocation.getCurrentPosition(
      (data) => {
        utils.addItemsIntoLocalStorage(
          {
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
          },
          false
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    if (signupFlag && profileSignup?.status !== "" && !profileSignup?.loading) {
      signupFlag = false;
      captchaRef.current.reset();
      if (profileSignup?.status === "1") {
        handleClose();
        if (
          profileSignup?.data?.user_type != "facebook" &&
          profileSignup?.data?.user_type != "google"
        ) {
          history.push("/profile-login");
        } else {
          const is_register = utils.getLocalStorageItems().is_register;
          if (is_register != "true") {
            history.push("/profile-signup");
          }
          // history.push("/profile-signup");
        }
      }
    }
  }, [profileSignup]);
  useEffect(() => {
    getGeolocationOfUser();
  }, []);
  // console.log(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY);
  const [userType, setUserType] = useState("");

  const handleSocialSignup = (type, provider) => {
    signInWithPopup(auth, provider)
      .then(async (res) => {
        setUserType(type);
        signupFlag = true;
        const email =
          res.user?.providerData[0]?.email ||
          res.user?.email ||
          (type === "facebook" && res.user?.providerData[0]?.phoneNumber);
        let data_visibility = {};
        let formData = new FormData();
        formData.append("name", res?.user?.displayName);
        email && formData.append("email", email);
        res?.user?.phoneNumber &&
          formData.append("phone", res?.user?.phoneNumber);
        formData.append("facebook_uid", res?.user?.uid);
        formData.append("user_type", type);
        res?.user?.photoURL &&
          formData.append("profile_picture", res?.user?.photoURL);

        data_visibility = {
          address: false,
          phone: false,
          email: false,
        };
        formData.append("data_visibility", JSON.stringify(data_visibility));
        // formData.append("recaptcha_token", captchaToken);
        const { latitude, longitude } = utils.getLocalStorageItems();
        if (latitude && longitude) {
          formData.append("latitude", latitude);
          formData.append("longitude", longitude);
        }
        if (token) {
          formData.append("referralToken", token);
        }
        var signupData = await signup(formData);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid className="create-profile-root">
      <Grid className="center">
        <Grid className="center">
          <img src={createProfileImage} alt="Create Profile Img" />
        </Grid>
        <MainHeading>Create Your HealthLOQ Digital Identity</MainHeading>
        <Typography variant="body2" light>
          The following information is optional. However, when interacting with
          many of our brands/producers, this information will be helpful to know
          so that appropriate communications, offers and enhancements that can
          allow for improved quality, effectiveness, additional products, and
          unique and personalized communication. You are able to provide as much
          or as little information as you want and can change this status at any
          time.
        </Typography>
      </Grid>
      <Grid className="cp-form-div">
        <Grid container alignItems="center" justifyContent="flex-end">
          <PublicPrivateSwitch
            checked={
              3 ===
              Object.entries(signupData)
                ?.slice(0, 4)
                ?.filter(
                  ([signupKey, item]) => signupKey !== "name" && item?.isPublic
                ).length
            }
            onChange={handleMasterToggle("create-profile")}
            isMasterToggle={true}
            id="signup-master-toggle"
          />
        </Grid>
        {Object.entries(signupData)
          ?.slice(0, 4)
          ?.map((obj, key) => {
            const [signupKey, item] = obj;
            return (
              <MuiTextfield
                key={key}
                value={item?.value}
                onChange={(e) =>
                  handleSignupDataChange(signupKey, e.target.value)
                }
                placeholder={item?.placeholder}
                match={match}
                required={item.required}
                error={item?.error}
                showLockIcon={["name"].includes(signupKey) ? false : true}
                lockIconState={item?.isPublic}
                handleLockIconClick={(e) =>
                  handleSignupDataChange(signupKey, "", "toggleIsPublic")
                }
                autoFocus={key === 0}
                switchId={`switch-${key}`}
              />
            );
          })}
        <Grid className="cp-profile-picture">
          <Grid>
            <label htmlFor="profile-picture-input-file">
              <CloudUploadRoundedIcon />
              {profile_picture
                ? profile_picture?.name
                : "Upload profile picture"}
            </label>
            {profile_picture && (
              <CloseRoundedIcon onClick={() => setProfile_picture(null)} />
            )}
          </Grid>
          <Grid className="center">
            {profile_picture && (
              <img
                src={URL.createObjectURL(profile_picture)}
                alt="profile_picture"
              />
            )}
          </Grid>
        </Grid>
        <ReCAPTCHA
          sitekey="6LfCFOgqAAAAAIMGKNWYF9caN15pFpg615POboPz"
          ref={captchaRef}
        />
        <Button
          profileBgBtn
          onClick={handleSubmit}
          loading={userType == "" && profileSignup?.loading}
        >
          Submit
        </Button>

        <Grid className={classes.hLoginFormOR}>
          <Typography variant="body1" color="grey50">
            <span>OR</span>
          </Typography>
        </Grid>
        <Grid container spacing={1} justifyContent="center">
          {(verifyEnvironment("dev") ||
            verifyEnvironment("local") ||
            verifyEnvironment("qa")) && (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleSocialSignup("facebook", facebookProvider)}
                startIcon={
                  userType === "facebook" && profileSignup?.loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FacebookIcon color="white" />
                  )
                }
                loginWithFacebookBtn
              >
                Sign up with Facebook
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleSocialSignup("google", googleProvider)}
              loginWithGoogleBtn
              startIcon={
                userType === "google" && profileSignup?.loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <GoogleIcon color="white" />
                )
              }
            >
              Sign up with Google
            </Button>
          </Grid>
        </Grid>

        <p className="psl-footer-link">
          Already have an account? <Link to={"/profile-login"}>Login</Link>{" "}
          here.
        </p>
      </Grid>
      <input
        type="file"
        className="display-none"
        id="profile-picture-input-file"
        onClick={(e) => (e.target.value = null)}
        onChange={(e) => {
          if (e.target?.files?.length === 1) {
            setProfile_picture(e.target.files[0]);
          }
        }}
      />
    </Grid>
  );
}

const mapStateToProps = ({ ProfileReducers: { profileSignup } }) => ({
  profileSignup,
});

const mapDispatchToProps = { signup };

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
