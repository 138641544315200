import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Avatar,
  Tooltip,
  makeStyles,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import {
  MainHeading,
  Button,
  MuiLinearProgress,
  Paragraph,
  SmProfileFooter,
} from "../../components/Profile";
import { getAllUserDetails } from "../../redux/actions/profileActions";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import { Link } from "../../components";
import UserAvatar from "../../components/Common/UserAvatar";
import SinglePost from "../../components/Profile/Post/SinglePost";
import PersonIcon from "@material-ui/icons/Person";
import CryptoJS from "crypto-js";

const useStyle = makeStyles((theme) => ({
  practitionerLink: {
    color: "#28A745",
    textDecoration: "underline",
  },
  practitionerProfile: {
    marginTop: "20px",
    width: "100%",
    "&>div": {
      alignItems: "center",
      flex: "0 0 100%",
      display: "flex",
      flexWrap: "wrap",
      height: "auto",
      // paddingRight: "20px",
      maxWidth: "100%",
      marginBottom: "10px",
      "&>div > label": {
        display: "block",
        marginBottom: "0",
      },
    },
    "&>p": {
      marginTop: 10,
    },
  },
  practitionerDoc: {
    marginTop: 10,
    "&>img": {
      marginRight: 10,
    },
  },
  practitionerProfileName: {
    // width: "100%",
    width: "auto",
    marginLeft: "10px",
    "&> svg": {
      fill: "#28A745",
      color: "#28A745",
      width: "15px",
      height: "15px",
    }
  },
  practitionerBio: {
    // flex: "0 0 75% !important",
    // maxWidth: "75% !important",
  },
  practitionerBioLink: {
    marginTop: "10px",
    width: "100%",
  },
  practitionerDocOne: {
    display: "inline-block",
    marginRight: "20px",
    textAlign: "center",
    "&>p": {
      marginTop: 10,
    },
  },
  practitionerDocTwo: {
    display: "inline-block",
    textAlign: "center",
    "&>p": {
      marginTop: 10,
    },
  },
  practitionerAvatar: {
    width: "50px",
    height: "50px",
  },
  backBtn: {
    marginTop: "10px",
    width: "100%",
    "&>button": {
      height: "50px",
      width: "100%",
      maxWidth: "200px",
    },
  },
  tabaleDetail: {
    width: "100%",
  },
  [theme.breakpoints.down(767)]: {
    practitionerProfile: {
      "&>div": {
        // flex: "0 0 100% !important",
        // maxWidth: "100% !important",
        // marginBottom: "20px",
      },
    },
  },
}));

export const PractitionerUserDetail = (props) => {
  const urlParams = useParams();
  const classes = useStyle();
  const {
    allUserData,
    getAllUserDetails,
    match,
    history,
    activePage,
    profilePost,
  } = props;
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [pracBio, setPracBio] = useState("");
  const [pracLink, setPracLink] = useState("");
  const [pracCredentials, setPracCredentials] = useState(null);
  const [pracPictuure, setPracPictuure] = useState(null);
  const [getuserData, setGetuserData] = useState(null);

  function decryptUrlSafe(encryptedValue) {
    const base64 = encryptedValue.replace(/-/g, "+").replace(/_/g, "/");
    const encryptedStr = CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8);
    const decrypted = CryptoJS.AES.decrypt(encryptedStr, process.env.REACT_APP_JWT_SECRET).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  useEffect(() => {
    if (urlParams?.user_id) {
      getAllUserDetails({ user_id: decryptUrlSafe(urlParams?.user_id), type: "userDetail" });
    }
  }, [urlParams?.user_id]);

  useEffect(() => {
    if (allUserData?.status === "1") {
      setGetuserData(allUserData?.data);
    }
  }, [allUserData]);

  return (
    <Grid className="profile-details-root">
      <Grid>
        {/* <Grid
	          container
	          alignItems="flex-start"
	          justifyContent="space-between"
	          className="profile-detail-header"
	        >
	          <MainHeading>{ getuserData?.practitioner == "Yes" ? "Practitioner " : ""}Profile</MainHeading>
	        </Grid> */}

        {allUserData?.loading && !allUserData?.data ? (
          <Grid container justifyContent="center">
            <MuiCircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              className={clsx(
                "profile-detail-header",
                classes.practitionerProfile
              )}
            >
              <Grid>
                <Grid className={classes.practitionerProfilemain}>
                  <label htmlFor="profile-picture-input-file">
                    <UserAvatar
                      gender={getuserData?.gender}
                      src={getuserData?.profile_picture}
                      size={50}
                      useCustomStyle={true}
                      className={classes.userAvatar}
                    />
                  </label>
                </Grid>
                <Paragraph className={classes.practitionerProfileName}>
                  {getuserData?.name} {getuserData?.practitioner == "Yes" ? <><Tooltip title={"Practitioner"} arrow><PersonIcon /></Tooltip></> : ""}
                </Paragraph>
                {/*<Grid className={classes.backBtn}>
				          					            <Button profileBgBtn onClick={() => history.go(-1)}>
				          					              	Back
				          					            </Button>
				          				        		</Grid>*/}
              </Grid>
              <Grid className={classes.practitionerBio}>
                <Paragraph className={classes.practitionerBioDescription}>
                  {getuserData?.practitioner_bio
                    ? getuserData?.practitioner_bio
                    : ""}
                </Paragraph>
                {getuserData?.practitioner_link &&
                  getuserData?.practitioner == "Yes" && (
                    <Paragraph className={classes.practitionerBioLink}>
                      <a
                        className={classes.practitionerLink}
                        target="_blank"
                        href={getuserData?.practitioner_link}
                      >
                        {getuserData?.practitioner_link}
                      </a>
                    </Paragraph>
                  )}

                {(getuserData?.practitioner_credentials ||
                  getuserData?.practitioner_pictuure) &&
                  getuserData?.practitioner == "Yes" && (
                    <Grid className={classes.practitionerDoc}>
                      <Grid className={classes.practitionerDocOne}>
                        {getuserData?.practitioner_credentials && (
                          <>
                            <img
                              width={100}
                              height={100}
                              src={getuserData?.practitioner_credentials}
                            />
                            <Paragraph>Credentials</Paragraph>
                          </>
                        )}
                      </Grid>
                      <Grid className={classes.practitionerDocTwo}>
                        {getuserData?.practitioner_pictuure && (
                          <>
                            <img
                              width={100}
                              height={100}
                              src={getuserData?.practitioner_pictuure}
                            />
                            <Paragraph>Pictures</Paragraph>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            {getuserData?.posts && getuserData?.posts.length > 0 && (
              <Grid>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className="profile-detail-header"
                ></Grid>
                {profilePost?.map((item, key) => {
                  return (
                    <SinglePost
                      post={item}
                      key={key}
                      postLocation={"userProfile"}
                    />
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  ProfileReducers: { allUserData },
  PostReducer: { profilePost },
}) => ({
  allUserData,
  profilePost,
});

const mapDispatchToProps = {
  getAllUserDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitionerUserDetail);
