import clsx from "clsx";
import React, { useState } from "react";
import { Box, Button, makeStyles, Typography } from "..";
import { AppImages } from "../../constants";
import { ProductInfoDialog } from "../Dialogs";

const {
  icon: { wrongIcon, correctIcon },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    maxWidth: 700,
    margin: "0 auto",
    "&>div": {
      "&:first-child": {
        display: "flex",
        alignItems: "center",
        padding: "5px 20px",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        background: "#28A74530",
        "&>h5": {
          textAlign: "center",
          fontWeight: 700,
          width: "calc(100% - 65px)",
        },
        "&>svg": {
          width: 50,
          height: 50,
          color: theme.palette.primary.main,
          marginLeft: 15,
        },
      },
      "&:nth-child(2)": {
        padding: "10px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderTop: `2px solid ${theme.palette.borderColor}`,
        "&>div": {
          "&:not(:last-child)": {
            marginBottom: 20,
          },
          "&>p": {
            textAlign: "center",
            "&:first-child": {
              fontWeight: 700,
            },
          },
        },
        "&>p": {
          width: "100%",
          textAlign: "center",
          "&>span": {
            fontWeight: 700,
          },
        },
      },
      "&:last-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 20px",
      },
    },
  },
  expiredDoc: {
    border: `2px solid #D6513F`,
    "&>div": {
      "&:first-child": {
        borderBottom: `2px solid #D6513F`,
        background: "#D6513F30",
        "&>svg": {
          color: "#D6513F",
        },
      },
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.borderColor}`,
      },
    },
  },
}));

export default function NewIntegrantBlockChinProof({ blockchainData }) {
  const classes = useStyle();
  const [openShowProofDialog, setOpenShowProofDialog] = useState(false);

  return (
    <Box
      className={clsx(
        classes.root,
        !blockchainData?.result && classes.expiredDoc,
        !blockchainData?.result && "blockchain-proof-expired-new"
        // !govEntity?.length && "blockchain-proof-error-msg"
      )}
    >
      <Box>
        <Typography variant="h5">
          Document Details{" "}
          {/* {!blockchainData?.result ? "Verified" : "Not Verified"} */}
        </Typography>
        {blockchainData?.result ? (
          <img src={correctIcon} height={40} alt="currect-icon" />
        ) : (
          <img src={wrongIcon} height={40} alt="wrong-icon" />
        )}
      </Box>
      <Box>
        {blockchainData?.data?.type === "document_hash" ? (
          <Box>
            <Typography variant="body1">Document Information</Typography>
            <Typography variant="body1">
              Document ID: {blockchainData?.data?.id}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">Product Information</Typography>
            <Typography variant="body1">
              Product Name: {blockchainData?.data?.Title}
            </Typography>
            <Typography variant="body1">
              Batch ID: {blockchainData?.data?.ExternalId}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="body1">Digitally Signed By</Typography>
          <Typography variant="body1">
            Organization Name: {blockchainData?.data?.OrganizationName}
          </Typography>
          <Typography variant="body1">
            Organization ID: {blockchainData?.data?.OrganizationId}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">Block Location</Typography>
          <Typography style={{ wordBreak: "break-word" }} variant="body1">
            Block ID: {blockchainData?.blockAddress?.IonText}
          </Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"flex-start"}
        className={classes.actionBtns}
      >
        <Button
          variant="contained"
          primaryDarkBtn
          onClick={() => setOpenShowProofDialog(true)}
        >
          View More
        </Button>
      </Box>
      <ProductInfoDialog
        open={openShowProofDialog}
        handleClose={() => setOpenShowProofDialog(false)}
        data={blockchainData?.data}
      />
    </Box>
  );
}
